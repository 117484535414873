// lib/routes/root/src/lib/configuration/head.tag.root.tsx

import { Links, Meta } from '@remix-run/react';
import whatsAppImage from '../styles/logo_files/pngs_svg/1x/asset_1.png';

interface HeadProps {
  title?: string;
}

const faviconHref = 'assets/favicons/favicon.ico';
const logo192 = 'assets/png/logo192.png';

export function HeadRootTagEntrycall({ title }: HeadProps) {
  return (
    <head>
      {title ? <title>{title}</title> : null}
      {/* //* Render meta tags defined in the meta function */}
      <Meta />
      {/* //* Character encoding for the document */}
      <meta charSet="utf-8" />
      {/* //* Viewport settings for responsive design */}
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, user-scalable=yes"
      />
      {/* //* General meta tags for SEO */}
      <meta
        property="og:description"
        content="61 Alness St, North York, ON M3J 2H2"
      />
      <meta
        name="keywords"
        content="house management, intercom, home automation, security, smart home, property management, communication"
      />
      {/* //* Theme color for the browser's UI */}
      <meta name="theme-color" content="#1D62CB" />
      {/* //* Apple-specific meta tags for web app capabilities */}
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta
        name="apple-mobile-web-app-status-bar-style"
        content="black-translucent"
      />
      {/* //* Application name for web app banners and tiles */}
      <meta name="application-name" content="Entrycall" />
      <meta name="apple-mobile-web-app-title" content="Entrycall" />
      {/* //* Mobile web app capability */}
      <meta name="mobile-web-app-capable" content="yes" />
      {/* //* Microsoft-specific meta tags for tiles */}
      <meta name="msapplication-TileColor" content="#000000" />
      <meta
        name="msapplication-TileImage"
        content="/icons/ms-icon-144x144.png"
      />
      <meta name="msapplication-config" content="/browserconfig.xml" />
      {/* //* Favicon and touch icons for various platforms */}
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/icons/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/icons/favicon-16x16.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={`url(/assets/logo_files/pngs_svg/1x/asset_2.png)`}
      />

      {/* //* LobbyWatch-specific meta tags */}
      <meta charSet="utf-8" />
      <link rel="icon" href="https://lobbywatch.ca/favicon.ico" />
      <meta
        name="description"
        content="Lobby Watch: Keeping Your Building Safe"
      />
      <meta name="theme-color" content="#000000" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge,chrome=1" />
      <meta name="msapplication-TileColor" content="#000000" />
      <meta
        name="msapplication-TileImage"
        content="https://lobbywatch.ca/logo192.png"
      />
      <meta property="og:site_name" content="LobbyWatch" />
      <meta
        property="og:title"
        content="Lobby Watch: Keeping Your Building Safe"
      />
      <meta property="og:image" content="https://lobbywatch.ca/logo192.png" />
      <meta property="og:type" content="website" />
      <meta property="og:image:type" content="image/png" />
      <meta property="og:image:width" content="300" />
      <meta property="og:image:height" content="300" />
      <meta property="og:url" content="https://lobbywatch.ca/" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="LobbyWatch" />
      <meta
        name="twitter:description"
        content="Lobby Watch: Keeping Your Building Safe"
      />
      <meta name="twitter:image" content="https://lobbywatch.ca/logo192.png" />
      <meta name="twitter:image:alt" content="LobbyWatch Logo" />
      <link rel="canonical" href="https://lobbywatch.ca/" />
      <link rel="apple-touch-icon" href="https://lobbywatch.ca/logo192.png" />

      {/* //* Meta tags for WhatsApp preview: Optimizes content display when shared on WhatsApp */}
      <meta property="og:title" content="Entrycall" />
      <meta
        property="og:description"
        content="Simple. Secure. Reliable EntryPhones."
      />
      <meta property="og:url" content="app.entrycall.ca" />
      <meta
        property="og:image"
        content={`/assets/logo_files/pngs_svg/1x/asset_2.png`}
      />

      {/* //* Link to stylesheets defined in the links function */}
      <Links />
    </head>
  );
}
